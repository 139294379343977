<template>
  <div class="giga-upload">
    <a-upload
      v-if="multiple"
      name="image"
      :file-list="images"
      list-type="picture-card"
      class="avatar-uploader"
      @change="handleChange"
      @preview="handlePreview"
      :customRequest="() => ''"
      :multiple="multiple"
    >
      <div v-if="fileList.length < 100">
        <loading-outlined v-if="loading"></loading-outlined>
        <plus-outlined v-else />
        <div style="margin-top: 8px">Upload</div>
      </div>
    </a-upload>
    <a-upload
      v-else
      :fileList="fileList"
      name="image"
      list-type="picture-card"
      class="avatar-uploader"
      :show-upload-list="false"
      @change="handleChange"
      :customRequest="() => ''"
    >
      <img v-if="imageUrl" :src="imageUrl" alt="" style="width:108px;height:108px" />
      <video v-else-if="videoUrl" :src="videoUrl" style="width:108px;height:108px;background:#000"></video>
      <div v-else>
        <loading-outlined v-if="loading"></loading-outlined>
        <plus-outlined v-else></plus-outlined>
        <div class="ant-upload-text">Upload</div>
      </div>
    </a-upload>
    <a-modal :visible="previewVisible" :title="previewTitle" :footer="null" @cancel="handleCancel">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
  
</template>
<script>
import { defineComponent, ref } from 'vue';
// import { message } from 'ant-design-vue';
import { upLoadImage } from '../libs/upload'

export default defineComponent({
  props: ['imageUrl', 'multiple', 'images', 'videoUrl', 'needGotoUrl'],
  setup(props, {emit}) {
    const fileList = ref([]);
    const loading = ref(false);
    const previewVisible = ref(false);
    const previewImage = ref('');
    const previewTitle = ref('');

    const handleChange = info => {
      if (info.file.status === 'uploading') {
        if (loading.value == true) return
        console.log(info)
        let fileList = info.fileList.filter(e => e.originFileObj)
        upLoad(fileList)
        loading.value = true;
        return;
      }
      if (info.file.status === 'removed') {
        emit('removeImg', info.file.id)
      }
    };

    const upLoad = (fileList) => {
      let file = fileList.shift()
      if (file) {
        upLoadImage(file, props.needGotoUrl).then(res => {
          console.log(res)
          let img = res.data.uploadFile
          emit('setImg', img)
          upLoad(fileList)
        }).catch(() => {
          loading.value = false
        })
      } else {
        loading.value = false
      }
    }

    const handleCancel = () => {
      previewVisible.value = false;
      previewTitle.value = '';
    };

    const handlePreview = async file => {
      console.log(file)

      previewImage.value = file.url || file.preview;
      previewVisible.value = true;
      previewTitle.value = file.name || file.url.substring(file.url.lastIndexOf('/') + 1);
    };

    return {
      fileList,
      loading,
      handleChange,
      upLoad,
      handleCancel,
      handlePreview,
      previewImage,
      previewVisible,
      previewTitle
    };
  },

});
</script>
<style lang="scss" scoped>
.giga-upload {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .img-block {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    img {
      width: 104px;
      height: 104px;
      margin-right: 8px;
      margin-bottom: 8px;
    }
  }
  
}
</style>
<style>
.avatar-uploader > .ant-upload {
  width: 104px;
  height: 104px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>