<template>
  <div>
    <div class="operations">
      <div style="flex: 1"></div>
      <a-button @click="(recordId = -1), (formType = 'add'), (showForm = true)">添加</a-button>
    </div>
    <TiyoTable :gData="dataSource" :gColumns="columns" />
    <RecipeForm :visible="showForm" :recordId="recordId" :formType="formType" :formData="formState" :recipeCategory="recipeCategory" :foods="foods" :recipesChoices="recipesChoices" @recipesTesting="recipesTesting" @formCancel="handleCancel" @formOk="handleOk" />
  </div>
</template>
<script>
import { testRecipes, testRecipesChoices, getRecipes, getFoods, editRecipe, testRecipe } from '../http/api';
import TiyoTable from '../components/TiyoTable.vue';
import RecipeForm from '../components/form/RecipeForm';
export default {
  components: {
    TiyoTable,
    RecipeForm,
  },
  data() {
    return {
      recipesChoices: [],

      recordId: -1,
      showForm: false,
      formType: 'add',

      formState: { bom: [{ grams: '', foodId: '' }] },
      dataSource: [],
      foods: [],
      recipeCategory: [
        {
          name: 'rc',
          display: '全肉配方',
        },
        {
          name: 'rc2',
          display: '高蛋白高脂肪配方',
        },
        {
          name: 'rc3',
          display: '高蛋白低脂肪配方',
        },
        {
          name: 'rc4',
          display: '低蛋白配方',
        },
      ],
      columns: [
        {
          title: 'id',
          dataIndex: 'id',
          key: 'id',
          width: 60,
        },
        {
          title: '名称',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: '产品介绍',
          dataIndex: 'description',
          key: 'description',
          customRender: val => {
            return (
              <a-tooltip title={val.value}>
                <div style={{ maxWidth: '100%' }}>
                  {val.value.substring(0, 5)}
                  {val.value.length > 5 ? '...' : ''}
                </div>
              </a-tooltip>
            );
          },
        },
        {
          title: '标签',
          dataIndex: 'tags',
          key: 'tags',
          customRender: val => {
            return <div>{val.value ? val.value.map(e => <span v-html={e + '，'}></span>) : ''}</div>;
          },
        },
        {
          title: '封面图',
          dataIndex: 'coverImage',
          key: 'coverImage',
          customRender: val => {
            return <img src={(val.value && val.value.url) || ''} style="width: 40px" />;
          },
        },
        {
          title: '宠物种类',
          dataIndex: 'applySpecies',
          key: 'applySpecies',
          customRender: val => {
            return <div>{val.value ? val.value.map(e => <span v-html={e + '，'}></span>) : ''}</div>;
          },
        },
        {
          title: '分类',
          dataIndex: 'recipeCategory',
          key: 'recipeCategory',
        },
        {
          title: '每月成本',
          dataIndex: 'monthlyCost',
          key: 'monthlyCost',
          width: 60,
        },
        {
          title: '是否发布',
          dataIndex: 'isPublished',
          key: 'isPublished',
          customRender: val => <span v-html={val.value ? '已发布' : '未发布'} />,
        },
        {
          title: '操作',
          dataIndex: 'operation',
          key: 'operation',
          customRender: val => {
            return (
              <div>
                <a-dropdown
                  trigger={['click']}
                  overlay={
                    <a-menu>
                      <a-menu-item onClick={() => this.testRecipe(val.record.id)}>
                        <span>验证</span>
                      </a-menu-item>
                      <a-menu-item onClick={() => this.editRecipe(val.record)}>
                        <span>编辑</span>
                      </a-menu-item>
                      <a-menu-item onClick={() => this.editRecipe(val.record, 'publish')}>
                        <span v-html={val.record.isPublished ? '取消发布' : '发布'}></span>
                      </a-menu-item>
                      <a-menu-item onClick={() => this.editRecipe(val.record, 'del')}>
                        <span>删除</span>
                      </a-menu-item>
                    </a-menu>
                  }
                >
                  <a-button>操作</a-button>
                </a-dropdown>
              </div>
            );
          },
        },
      ],
    };
  },
  async created() {
    this.getRecipes();
    this.getFoods();

    // 验证食材
    await this.testRecipesChoices();
  },
  methods: {
    // 验证食材选项
    async testRecipesChoices() {
      const { data } = await testRecipesChoices();
      const { affcoLimit, specialNeeds } = data.choices;
      this.recipesChoices = [...affcoLimit, ...specialNeeds];
    },
    // 验证食材事件
    async recipesTesting({ limitName, hook }) {
      if (!limitName) {
        return this.$message.error('请先选择校验方案');
      }

      // Get Data from Test Recipes
      const { data } = await testRecipes({ variables: { limitName, id: this.recordId } });

      // Running Hook
      hook(data.testRecipe);
    },

    testRecipe(id) {
      testRecipe({ variables: { id } }).then(res => {
        if (res.errors) {
          this.$message.error(res.errors[0].message);
        } else {
          this.$message.success('通过');
        }
      });
    },
    setImg(e, type) {
      let img = e[0];
      let formState = this.formState;
      formState[type] = img.url;
      this.formState = JSON.parse(JSON.stringify(formState));
    },

    handleOk(formState) {
      editRecipe({ variables: formState })
        .then(res => {
          console.log(res);
          this.handleCancel();
          this.getRecipes();
        })
        .catch(err => {
          console.log(err);
        });
    },

    editRecipe(record, type) {
      if (type == 'del') {
        this.handleOk({ id: record.id, isDeleted: true });
      } else if (type == 'publish') {
        this.handleOk({ id: record.id, isPublished: !record.isPublished });
      } else {
        console.log(record);
        let formState = JSON.parse(JSON.stringify(record));
        if (formState.recipeCategory) formState.recipeCategory = this.recipeCategory.filter(e => e.display == formState.recipeCategory)[0].name;
        if (formState.bom && formState.bom.length)
          formState.bom = formState.bom.map(e => {
            return { foodId: e.food.id, grams: e.grams, optionalFoodIds: e.optionalFoods && e.optionalFoods.length ? e.optionalFoods.map(el => el.id) : [] };
          });
        console.log(formState);
        this.formState = formState;

        this.recordId = record.id;
        this.formType = 'edit';
        this.showForm = true;
      }
    },
    handleCancel() {
      this.showForm = false;
      this.formState = {};
    },
    getRecipes() {
      getRecipes().then(res => {
        if (res.errors) {
          this.$message.error(res.errors[0].message);
          return;
        }
        let dataSource = res.data.recipes;
        this.dataSource = dataSource;
      });
    },
    getFoods() {
      getFoods().then(res => {
        this.foods = res.data.foods;
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
