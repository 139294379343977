import { uploadFiles } from '../http/api'
import { message } from 'ant-design-vue';

export const upLoadImage = (file) => {
  console.log(file)
  return new Promise((resolve, reject) => {
    let o = {
      query: `mutation(
        $file: Upload!
        $metaData: MetaDataInput
        $mimeType: String
        $name: String) {
        uploadFile(file: $file, metaData: $metaData, mimeType: $mimeType, name: $name) {
          file {
            id
            bucket
            mimeType
            name
            url
          }
        }
      }`,
      variables: {
        file: null,
        name: file.name,
        mimeType: file.type
      }
    }
    let map = {
      'file': ['variables.file']
    }
    let fd = new FormData()
    fd.append('operations', JSON.stringify(o))
    fd.append('map', JSON.stringify(map))
    fd.append('file', file.originFileObj)
    uploadFiles(fd).then(imgFile => {
      resolve(imgFile)
      // return imgFile.data.uploadImage.fileUpload.fileUrl
    }).catch(err => {
      message.error('上传失败')
      reject(err)
    })
  })
}