<template>
  <div>
    <a-modal :visible="visible" title="添加食谱" destroyOnClose :maskClosable="false" @ok="handleOk" @cancel="handleCancel">
      <a-form ref="form" :model="formState" name="form" :validateOnRuleChange="false" :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }" autocomplete="off" scrollToFirstError>
        <a-form-item label="食谱名称" name="name" :rules="[{ required: true, message: '输入产品名称' }]">
          <a-input v-model:value="formState.name" name="name" />
        </a-form-item>
        <a-form-item label="封面图" name="coverImage">
          <TiyoUpload v-model:value="formState.coverImage" name="coverImage" :imageUrl="(formState.coverImage && formState.coverImage.url) || ''" @setImg="setImg($event, 'coverImage')" />
        </a-form-item>
        <a-form-item label="适用宠物" name="applySpecie">
          <a-select ref="select" mode="multiple" v-model:value="formState.applySpecies" style="width: 100%">
            <a-select-option value="cat">猫</a-select-option>
            <a-select-option value="dog">狗</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="菜谱分类" name="recipeCategory">
          <a-select ref="select" v-model:value="formState.recipeCategory" style="width: 100%">
            <a-select-option v-for="item in recipeCategory" :key="item.name" :value="item.name">{{ item.display }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="描述" name="description">
          <a-textarea v-model:value="formState.description" name="description" />
        </a-form-item>
        <a-form-item label="标签" name="tags">
          <a-select v-model:value="formState.tags" mode="tags" style="width: 100%" :token-separators="[',']"></a-select>
        </a-form-item>
        <a-form-item v-for="(item, index) in formState.bom" :key="index" label="单品" :name="['bom']">
          <div style="width: 100%; display: flex">
            <a-input-group compact style="width: 80%; margin-right: 8px">
              <a-select v-model:value="item.foodId" style="width: 200px">
                <a-select-option v-for="food in foods" :key="food.id" :value="food.id">{{ food.originalName }}</a-select-option>
              </a-select>
              <a-input v-model:value="item.grams" style="width: calc(100% - 200px)" />
            </a-input-group>
            <SyncOutlined class="dynamic-delete-button" style="margin-right: 6px" @click="showAddReplace(index)" />
            <MinusCircleOutlined v-if="formState.bom.length > 1" class="dynamic-delete-button" @click="removeBom(item)" />
          </div>
          <div v-if="item.optionalFoodIds && item.optionalFoodIds.length" style="width: 100%; display: flex; align-items: center; margin-top: 10px">
            <p style="margin: 0">可替换：</p>
            <a-select :value="item.optionalFoodIds" style="flex: 1" disabled mode="multiple">
              <a-select-option v-for="food in foods" :key="food.id" :value="food.id">{{ food.originalName }}</a-select-option>
            </a-select>
          </div>
        </a-form-item>
        <a-form-item>
          <a-button type="dashed" style="width: 60%; margin-left: 100px" @click="addBom">
            <PlusOutlined />
            添加单品
          </a-button>
        </a-form-item>
        <a-form-item label="图片列表" name="images">
          <TiyoUpload v-model:value="formState.images" name="images" :images="formState.images || []" :multiple="true" @setImg="setImg($event, 'images', 'multiple')" @removeImg="removeImg($event, 'images')" />
        </a-form-item>
        <a-form-item label="排序" name="displayOrder" :rules="[{ required: true, message: '输入序号' }]">
          <a-input v-model:value="formState.displayOrder" name="displayOrder" />
        </a-form-item>

        <!-- 关于食谱营养素验证的相关功能 -->
        <template v-if="formType === 'edit'">
          <a-divider dashed orientation="center" :plain="true" :style="{ color: 'gray' }">食谱营养验证（临时方案）</a-divider>

          <a-form-item label="健康需求" name="AAA">
            <a-input-group compact>
              <a-select v-model:value="formState.limitName" style="width: calc(100% - 120px)">
                <a-select-option v-for="{ name } in recipesChoices" :value="name" :key="name">{{ name }}</a-select-option>
              </a-select>
              <a-button type="primary" style="width: 100px; margin-left: 20px" @click="testRecipeHandler">验证</a-button>
            </a-input-group>
          </a-form-item>
          <a-form-item label="验证结果" name="AAA">
            <a-typography>
              <a-typography-text type="success" v-if="isOk === true">通过</a-typography-text>
              <a-typography-text type="danger" v-if="isOk === false">不通过</a-typography-text>
              <a-typography-text disabled v-if="isOk === -1">未知</a-typography-text>
            </a-typography>
          </a-form-item>
          <a-form-item label="具体数据" name="AAA" v-if="errMsg">
            <pre>{{ errMsg }}</pre>
          </a-form-item>
        </template>
      </a-form>
    </a-modal>
    <a-modal :visible="isShowReplace" title="添加替换食材" destroyOnClose :maskClosable="false" @ok="addReplace" @cancel="isShowReplace = false">
      <a-select placeholder="Please select" mode="multiple" v-model:value="optionalFoodIds" style="width: 100%" :options="foods" :field-names="{ label: 'name', value: 'id' }" optionFilterProp="name" />
    </a-modal>
  </div>
</template>
<script>
import { ref, watch } from 'vue';
import TiyoUpload from '../TiyoUpload.vue';

export default {
  name: 'ProductForm',
  components: {
    TiyoUpload,
  },
  props: ['visible', 'recordId', 'formData', 'formType', 'recipeCategory', 'recipesChoices', 'foods'],
  setup(props) {
    const formState = ref(props.formData);
    const isShowReplace = ref(false);
    const optionalFoodIds = ref([]);
    let bomIndex = -1;
    watch(
      () => props.formData,
      newVal => (formState.value = newVal),
    );
    const addBom = () => {
      if (!formState.value.bom) formState.value.bom = [];
      formState.value.bom.push({
        grams: '',
        foodId: '',
      });
    };
    const showAddReplace = index => {
      bomIndex = index;
      let bom = formState.value.bom[bomIndex];
      optionalFoodIds.value = bom.optionalFoodIds || [];
      isShowReplace.value = true;
    };
    const addReplace = () => {
      if (bomIndex >= 0) {
        let bom = formState.value.bom[bomIndex];
        bom.optionalFoodIds = optionalFoodIds.value;
        formState.value.bom[bomIndex] = bom;
        isShowReplace.value = false;
        bomIndex = -1;
        optionalFoodIds.value = [];
      }
    };
    const removeBom = item => {
      console.log(item);
      let index = formState.value.bom.indexOf(item);
      if (index !== -1) {
        formState.value.bom.splice(index, 1);
      }
    };
    return {
      removeBom,
      formState,
      isShowReplace,
      optionalFoodIds,
      form: ref(null),
      addBom,
      addReplace,
      showAddReplace,
    };
  },

  data() {
    return {
      isOk: false,
      errMsg: '',
    };
  },

  watch: {
    formState: {
      handler() {
        this.isOk = -1;
        this.errMsg = '';
      },

      deep: true,
      immediate: true,
    },
  },

  methods: {
    setImg(e, param, multiple) {
      console.log(e);
      let img = e.file;
      let formState = this.formState;
      if (multiple) {
        if (!formState[param] || !Array.isArray(formState[param])) formState[param] = [];
        formState[param] = formState[param].concat(img);
      } else {
        formState[param] = img;
      }
      console.log(formState);
      this.formState = JSON.parse(JSON.stringify(formState));
    },
    removeImg(id, param) {
      console.log(id);
      let formState = this.formState;
      let images = formState[param];
      images.forEach((e, index) => {
        if (e.id == id) {
          images.splice(index, 1);
        }
      });
      formState[param] = images;
      this.formState = JSON.parse(JSON.stringify(formState));
    },
    handleOk() {
      this.form
        .validateFields()
        .then(res => {
          console.log(res);
          let formState = Object.assign(this.formState, res);
          if (formState.coverImage) formState.coverImage = formState.coverImage.id;
          if (formState.images && formState.images.length) formState.images = formState.images.map(e => e.id);
          this.$emit('formOk', formState);
        })
        .catch(err => {
          console.error(err);
          this.$message.error(err.errorFields[0].errors[0]);
        });
    },
    handleCancel() {
      this.$emit('formCancel');
    },

    // 校验食谱
    testRecipeHandler() {
      const that = this;

      this.$emit('recipesTesting', {
        limitName: this.formState.limitName,
        hook({ isOk, errMsg, tonicGrams } = {}) {
          that.isOk = isOk;
          that.errMsg = errMsg;
        },
      });
    },
  },
};
</script>
<style>
.ant-form-item-control-input-content {
  /* display: flex; */
}
.dynamic-delete-button {
  cursor: pointer;
  position: relative;
  top: 4px;
  font-size: 24px;
  color: #999;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: #777;
}
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}
</style>
